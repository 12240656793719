import * as i0 from '@angular/core';
import { InjectionToken, Optional, Inject, Injectable, SkipSelf, NgModule } from '@angular/core';
import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpContext, HttpParams } from '@angular/common/http';

/**
 * Custom HttpParameterCodec
 * Workaround for https://github.com/angular/angular/issues/18261
 */
class CustomHttpParameterCodec {
  encodeKey(k) {
    return encodeURIComponent(k);
  }
  encodeValue(v) {
    return encodeURIComponent(v);
  }
  decodeKey(k) {
    return decodeURIComponent(k);
  }
  decodeValue(v) {
    return decodeURIComponent(v);
  }
}
const BASE_PATH = new InjectionToken('basePath');
const COLLECTION_FORMATS = {
  'csv': ',',
  'tsv': '   ',
  'ssv': ' ',
  'pipes': '|'
};
class Configuration {
  /**
   *  @deprecated Since 5.0. Use credentials instead
   */
  apiKeys;
  username;
  password;
  /**
   *  @deprecated Since 5.0. Use credentials instead
   */
  accessToken;
  basePath;
  withCredentials;
  /**
   * Takes care of encoding query- and form-parameters.
   */
  encoder;
  /**
   * Encoding of various path parameter
   * <a href="https://github.com/OAI/OpenAPI-Specification/blob/main/versions/3.1.0.md#style-values">styles</a>.
   * <p>
   * See {@link README.md} for more details
   * </p>
   */
  encodeParam;
  /**
   * The keys are the names in the securitySchemes section of the OpenAPI
   * document. They should map to the value used for authentication
   * minus any standard prefixes such as 'Basic' or 'Bearer'.
   */
  credentials;
  constructor(configurationParameters = {}) {
    this.apiKeys = configurationParameters.apiKeys;
    this.username = configurationParameters.username;
    this.password = configurationParameters.password;
    this.accessToken = configurationParameters.accessToken;
    this.basePath = configurationParameters.basePath;
    this.withCredentials = configurationParameters.withCredentials;
    this.encoder = configurationParameters.encoder;
    if (configurationParameters.encodeParam) {
      this.encodeParam = configurationParameters.encodeParam;
    } else {
      this.encodeParam = param => this.defaultEncodeParam(param);
    }
    if (configurationParameters.credentials) {
      this.credentials = configurationParameters.credentials;
    } else {
      this.credentials = {};
    }
    // init default bearerAuth credential
    if (!this.credentials['bearerAuth']) {
      this.credentials['bearerAuth'] = () => {
        return typeof this.accessToken === 'function' ? this.accessToken() : this.accessToken;
      };
    }
  }
  /**
   * Select the correct content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   * @param contentTypes - the array of content types that are available for selection
   * @returns the selected content-type or <code>undefined</code> if no selection could be made.
   */
  selectHeaderContentType(contentTypes) {
    if (contentTypes.length === 0) {
      return undefined;
    }
    const type = contentTypes.find(x => this.isJsonMime(x));
    if (type === undefined) {
      return contentTypes[0];
    }
    return type;
  }
  /**
   * Select the correct accept content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct accept content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   * @param accepts - the array of content types that are available for selection.
   * @returns the selected content-type or <code>undefined</code> if no selection could be made.
   */
  selectHeaderAccept(accepts) {
    if (accepts.length === 0) {
      return undefined;
    }
    const type = accepts.find(x => this.isJsonMime(x));
    if (type === undefined) {
      return accepts[0];
    }
    return type;
  }
  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   * @return True if the given MIME is JSON, false otherwise.
   */
  isJsonMime(mime) {
    const jsonMime = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
  }
  lookupCredential(key) {
    const value = this.credentials[key];
    return typeof value === 'function' ? value() : value;
  }
  defaultEncodeParam(param) {
    // This implementation exists as fallback for missing configuration
    // and for backwards compatibility to older typescript-angular generator versions.
    // It only works for the 'simple' parameter style.
    // Date-handling only works for the 'date-time' format.
    // All other styles and Date-formats are probably handled incorrectly.
    //
    // But: if that's all you need (i.e.: the most common use-case): no need for customization!
    const value = param.dataFormat === 'date-time' && param.value instanceof Date ? param.value.toISOString() : param.value;
    return encodeURIComponent(String(value));
  }
}

/**
 * IdealSupply Vendors API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class VendorsApi {
  httpClient;
  basePath = 'https://api.idealsupply.com/vendors/v0';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  idGet(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling idGet.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (bearerAuth) required
    localVarCredential = this.configuration.lookupCredential('bearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchVendorList(skip, limit, observe = 'body', reportProgress = false, options) {
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (skip !== undefined && skip !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, skip, 'skip');
    }
    if (limit !== undefined && limit !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, limit, 'limit');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (bearerAuth) required
    localVarCredential = this.configuration.lookupCredential('bearerAuth');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function VendorsApi_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || VendorsApi)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: VendorsApi,
    factory: VendorsApi.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VendorsApi, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
const APIS = [VendorsApi];

/**
 * IdealSupply Vendors API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * IdealSupply Vendors API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

class ApiModule {
  static forRoot(configurationFactory) {
    return {
      ngModule: ApiModule,
      providers: [{
        provide: Configuration,
        useFactory: configurationFactory
      }]
    };
  }
  constructor(parentModule, http) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
  static ɵfac = function ApiModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ApiModule)(i0.ɵɵinject(ApiModule, 12), i0.ɵɵinject(i1.HttpClient, 8));
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ApiModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [],
      exports: [],
      providers: []
    }]
  }], () => [{
    type: ApiModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: i1.HttpClient,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { APIS, ApiModule, BASE_PATH, COLLECTION_FORMATS, Configuration, VendorsApi };
